

import React, { useState, useContext } from 'react';
import GridLoader from "react-spinners/GridLoader";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { LuIndianRupee } from 'react-icons/lu';
import './cart.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { IoMdArrowDropright } from 'react-icons/io';
import { CartContext } from '../context/CartContext';
import Input from 'react-phone-number-input/input'

const Cart = () => {
  const { items, addItem, removeItem, totalPrice,clearCart } = useContext(CartContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  function containsNumber(str) {
    return /\d/.test(str);
  }

  const handleCheckout = async () => {
    const username = containsNumber(name);
    if (phoneNumber.length < 10 || name === '' || username) {
      setShowModal(true);
      alert('Please Enter Valid Phone No. & Name');
      return;
    }
    const bId = localStorage.getItem("businessId");
    setLoading(true);
    try {
      const orderDetailItems = items.map((item) => ({
        item: { id: item.id },
        quantity: item.quantity,
      }));

      const payload = {
        customerName: name,
        businessId:bId,
        phoneNumber: `${phoneNumber}`,
        orderDetailItems,
      };

      console.log( "payload at cart  - ",  payload)
    

    //  const response = await axios.post(`${process.env.REACT_APP_API_URL}orders`, payload);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}orders`, payload);


      if (response && response.status === 200 && response.data) {
        const data = response.data;
        
        
        navigate('/checkoutresponse', { 
          state: { 
            data: data.orderDetailItems || [], 
            total: data.totalAmount || 0,
            cgst: data.cgst || 0,
            sgst: data.sgst || 0,
            orderNumber: data.orderNumber || '',
            status: data.currentStatusDisplayName || '',
            BusinessName: data.orderDetailItems?.[0]?.item?.category?.business?.name || ''
          } 
        });
        clearCart();
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      alert('Checkout failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="loader-overlay">
        <GridLoader size={50} color="black" />
      </div>
    );
  }

  return (
    <div>
      <div className='main_2_div'>
        <h2>Cart</h2>

        {items.length === 0 ? (
          <p>No items in the cart.</p>
        ) : (
          <ul className='cart'>
            {items.map((item, index) => (
              <div className='cartitems' key={index}>
                <div className='price_per_qty'>
                  <p style={{ fontWeight: '600', fontSize: '17px' }}>{item.name}</p>
                  <div className='btnqty'>
                    <button className='plusminusbutton' onClick={() => removeItem(item)}>
                      <FaMinus />
                    </button>
                    <span style={{ fontSize: '18px', fontWeight: '500' }}>{item.quantity}</span>
                    <button className='plusminusbutton' onClick={() => addItem(item)}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className='price_per_qty'>
                  <h6>
                    <span className='pricecart'>
                      <LuIndianRupee />
                    </span>
                    {item.price}
                  </h6>
                  <h6>
                    <span className='pricecart'>
                      <LuIndianRupee />
                    </span>
                    {item.price * item.quantity}
                  </h6>
                </div>
              </div>
            ))}
          </ul>
        )}

        <div className='cart_footer' onClick={() => setShowModal(true)}>
          <h3>
            Check Out <IoMdArrowDropright />
          </h3>
          <h2>
            <LuIndianRupee />
            {totalPrice}
          </h2>
        </div>
      </div>

      <Modal aria-labelledby='contained-modal-title-vcenter' centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <p>Please Enter Your Details To Continue</p>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='formPhoneNumber'>
              <Input
              style={{width:"100%",padding:"10px",borderRadius:"10px",margin:"1px solid gainsboro"}}
               country="IN"
                placeholder='Enter Phone Number'
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </Form.Group>
            <Form.Group controlId='formName'>
              <Form.Label>{' '}</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Your Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer >
          <Button variant='warning' style={{ marginRight: '35%' }} onClick={handleCheckout} >
            Checkout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Cart;