// import React, { useEffect, useState } from "react";
// import "./currentOrder.css";
// import { FaMinus, FaPlus } from "react-icons/fa";
// import axios from "axios";

// const CurrentOrders = () => {
//   const [datas, setDatas] = useState([]);

//   const recalculateOrderTotals = (order) => {
//     const totalAmount = order.orderDetailItems.reduce(
//       (sum, item) => sum + item.price * item.quantity,
//       0
//     );
//     const sgst = totalAmount * 0.09; // Assuming 9% SGST, adjust as needed
//     const cgst = totalAmount * 0.09; // Assuming 9% CGST, adjust as needed
//     const total = totalAmount + sgst + cgst;

//     return {
//       ...order,
//       totalAmount,
//       sgst,
//       cgst,
//       total,
//     };
//   };

//   const updateItemQuantity = (orderId, itemIndex, delta) => {
//     setDatas((prevDatas) => {
//       return prevDatas.map((order) => {
//         if (order.id === orderId) {
//           const updatedItems = [...order.orderDetailItems];
//           if (!updatedItems[itemIndex].notAvailable) {
//             const newQuantity = updatedItems[itemIndex].quantity + delta;
//             if (newQuantity > 0) {
//               updatedItems[itemIndex] = {
//                 ...updatedItems[itemIndex],
//                 quantity: newQuantity,
//               };
//             } else {
//               updatedItems.splice(itemIndex, 1);
//             }
//           }
//           const updatedOrder = {
//             ...order,
//             orderDetailItems: updatedItems,
//           };
//           return recalculateOrderTotals(updatedOrder);
//         }
//         return order;
//       });
//     });
//   };

//   const handleStatusUpdate = async (order, statusEndpoint) => {
//     const itemsToSend = order.orderDetailItems.filter(
//       (item) => item.quantity > 0 && !item.notAvailable
//     );
//     const dataToSend = {
//       orderId: order.id,
//       orderNumber: order.orderNumber,
//       items: itemsToSend.map((item) => ({
//         itemId: item.item.id,
//         name: item.item.name,
//         quantity: item.quantity,
//         price: item.price,
//       })),
//     };

//     try {
//       alert(`Are You sure You Want To ${statusEndpoint} This Order`)
//       console.log(dataToSend);
//       const response = await axios.put(
//         `${process.env.REACT_APP_API_URL}orders/${dataToSend.orderId}/${statusEndpoint}`
//       );
//       console.log("API response:", response.data);
//       CurrentOrder();
//     } catch (error) {
//       console.error("API call error:", error);
//     }
//   };

//   const getLastSegmentOfOrderNumber = (orderNumber) => {
//     const segments = orderNumber.split("-");
//     return segments[segments.length - 1];
//   };

//   useEffect(() => {
//     CurrentOrder();
//   }, []);

//   const CurrentOrder = async () => {
//     try {
//       const businessId = localStorage.getItem("businessId");
//       const responseGet = await axios.get(
//         `${process.env.REACT_APP_API_URL}orders/current/${businessId}`
//       );
//       setDatas(responseGet.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const renderButtons = (order) => {
//     switch (order.currentStatusDisplayName) {
//       case "Created":
//         return (
//           <>
//             <button
//               type="button"
//               style={{ padding: "10px 30px" }}
//               className="btn btn-success"
//               onClick={() => handleStatusUpdate(order, "accept")}
//             >
//               Accept
//             </button>
//             <button
//               type="button"
//               style={{ padding: "10px 30px" }}
//               className="btn btn-danger"
//             >
//               Reject
//             </button>
//           </>
//         );
//       case "Accepted":
//         return (
//           <button
//             type="button"
//             style={{ padding: "10px 30px" }}
//             className="btn btn-success"
//             onClick={() => handleStatusUpdate(order, "completed")}
//           >
//             Complete
//           </button>
//         );
//       case "Completed":
//         return (
//           <button
//             type="button"
//             style={{ padding: "10px 30px" }}
//             className="btn btn-success"
//             onClick={() => handleStatusUpdate(order, "paid")}
//           >
//             Paid
//           </button>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>
//       <div className="main_2_div">
//         {datas.length === 0 ? (
//           <p>No orders available.</p>
//         ) : (
//           <ul className="cart">
//             {datas.map((order) => (
//               <div className="current-orders-category" key={order.id}>
//                 <h6>
//                   Order#: {getLastSegmentOfOrderNumber(order.orderNumber)} , ({" "}
//                   {order.currentStatusDisplayName} )
//                 </h6>
//                 {order.orderDetailItems.map((item, itemIndex) => (
//                   <div className="c-o-cartitems" key={itemIndex}>
//                     <div className="price_per_qty">
//                       <p>{item.item.name}</p>
//                       {order.currentStatusDisplayName === "Created" ? (
//                         <div className="btnqty">
//                           <button
//                             className="c-o-plusminusbutton"
//                             onClick={() =>
//                               updateItemQuantity(order.id, itemIndex, -1)
//                             }
//                           >
//                             <FaMinus />
//                           </button>
//                           <span>{item.quantity}</span>
//                           <button
//                             className="c-o-plusminusbutton"
//                             onClick={() =>
//                               updateItemQuantity(order.id, itemIndex, 1)
//                             }
//                           >
//                             <FaPlus />
//                           </button>
//                         </div>
//                       ) : (
//                         <div className="btnqtyelse">
//                           <span>{item.quantity}</span>
//                         </div>
//                       )}
//                     </div>
//                     {item.notAvailable && (
//                       <p style={{ color: "red" }}>Not Available</p>
//                     )}
//                   </div>
//                 ))}
//                 {order.currentStatusDisplayName === "Completed" && (
//                   <div className="order-total">
//                     <p>Total Amount: Rs{order.totalAmount.toFixed(2)}</p>
//                   </div>
//                 )}
//                 <div className="accept-reject-button">
//                   {renderButtons(order)}
//                 </div>
//               </div>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// };

// export default CurrentOrders;

// import React, { useEffect, useState } from "react";
// import "./currentOrder.css";
// import { FaMinus, FaPlus } from "react-icons/fa";
// import axios from "axios";

// const CurrentOrders = () => {
//   const [datas, setDatas] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const recalculateOrderTotals = (order) => {
//     const totalAmount = order.orderDetailItems.reduce(
//       (sum, item) => sum + item.price * item.quantity,
//       0
//     );
//     const sgst = totalAmount * 0.09; // Assuming 9% SGST, adjust as needed
//     const cgst = totalAmount * 0.09; // Assuming 9% CGST, adjust as needed
//     const total = totalAmount + sgst + cgst;

//     return {
//       ...order,
//       totalAmount,
//       sgst,
//       cgst,
//       total,
//     };
//   };

//   const updateItemQuantity = (orderId, itemIndex, delta) => {
//     setDatas((prevDatas) => {
//       return prevDatas.map((order) => {
//         if (order.id === orderId) {
//           const updatedItems = [...order.orderDetailItems];
//           if (!updatedItems[itemIndex].notAvailable) {
//             const newQuantity = updatedItems[itemIndex].quantity + delta;
//             if (newQuantity > 0) {
//               updatedItems[itemIndex] = {
//                 ...updatedItems[itemIndex],
//                 quantity: newQuantity,
//               };
//             } else {
//               updatedItems.splice(itemIndex, 1);
//             }
//           }
//           const updatedOrder = {
//             ...order,
//             orderDetailItems: updatedItems,
//           };
//           return recalculateOrderTotals(updatedOrder);
//         }
//         return order;
//       });
//     });
//   };

//   const handleStatusUpdate = async (order, statusEndpoint) => {
//     // Disable multiple simultaneous updates
//     if (loading) return;

//     try {
//       setLoading(true);
//       const confirmed = window.confirm(`Are you sure you want to ${statusEndpoint} this order?`);
//       if (!confirmed) return;

//       const itemsToSend = order.orderDetailItems.filter(
//         (item) => item.quantity > 0 && !item.notAvailable
//       );
//       const dataToSend = {
//         orderId: order.id,
//         orderNumber: order.orderNumber,
//         items: itemsToSend.map((item) => ({
//           itemId: item.item.id,
//           name: item.item.name,
//           quantity: item.quantity,
//           price: item.price,
//         })),
//       };

//       const response = await axios.put(
//         `${process.env.REACT_APP_API_URL}orders/${dataToSend.orderId}/${statusEndpoint}`,
//         dataToSend
//       );

//       // Refresh orders after successful update
//       await CurrentOrder();
//     } catch (error) {
//       console.error("API call error:", error);
//       alert("Failed to update order status. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const getLastSegmentOfOrderNumber = (orderNumber) => {
//     const segments = orderNumber.split("-");
//     return segments[segments.length - 1];
//   };

//   useEffect(() => {
//     CurrentOrder();
//   }, []);

//   const CurrentOrder = async () => {
//     try {
//       const businessId = localStorage.getItem("businessId");
//       const responseGet = await axios.get(
//         `${process.env.REACT_APP_API_URL}orders/current/${businessId}`
//       );
//       setDatas(responseGet.data);
//     } catch (error) {
//       console.error(error);
//       alert("Failed to fetch current orders");
//     }
//   };

//   const renderButtons = (order) => {
//     // Add console log to debug order status
//     console.log("Current Order Status:", order.currentStatusDisplayName);

//     // Use toLowerCase to make comparison case-insensitive
//     const status = order.currentStatusDisplayName?.toLowerCase() || '';

//     switch (status) {
//       case "created":
//         return (
//           <>
//             <button
//               type="button"
//               style={{ padding: "10px 30px", marginRight: "10px" }}
//               className="btn btn-success"
//               onClick={() => handleStatusUpdate(order, "accept")}
//               disabled={loading}
//             >
//               {loading ? "Processing..." : "Accept"}
//             </button>
//             <button
//               type="button"
//               style={{ padding: "10px 30px" }}
//               className="btn btn-danger"
//               onClick={() => handleStatusUpdate(order, "reject")}
//               disabled={loading}
//             >
//               {loading ? "Processing..." : "Reject"}
//             </button>
//           </>
//         );
//       case "accepted":
//         return (
//           <button
//             type="button"
//             style={{ padding: "10px 30px" }}
//             className="btn btn-success"
//             onClick={() => handleStatusUpdate(order, "completed")}
//             disabled={loading}
//           >
//             {loading ? "Processing..." : "Complete"}
//           </button>
//         );
//       case "completed":
//         return (
//           <button
//             type="button"
//             style={{ padding: "10px 30px" }}
//             className="btn btn-success"
//             onClick={() => handleStatusUpdate(order, "paid")}
//             disabled={loading}
//           >
//             {loading ? "Processing..." : "Paid"}
//           </button>
//         );
//       default:
//         // Add a console log for unexpected status
//         console.warn("Unexpected order status:", order.currentStatusDisplayName);
//         return null;
//     }
//   };

//   return (
//     <div>
//       <div className="main_2_div">
//         {datas.length === 0 ? (
//           <p>No orders available.</p>
//         ) : (
//           <ul className="cart">
//             {datas.map((order) => (
//               <div className="current-orders-category" key={order.id}>
//                 <h6>
//                   Order#: {getLastSegmentOfOrderNumber(order.orderNumber)} , ({" "}
//                   {order.currentStatusDisplayName} )
//                 </h6>
//                 {order.orderDetailItems.map((item, itemIndex) => (
//                   <div className="c-o-cartitems" key={itemIndex}>
//                     <div className="price_per_qty">
//                       <p>{item.item.name}</p>
//                       {order.currentStatusDisplayName === "Created" ? (
//                         <div className="btnqty">
//                           <button
//                             className="c-o-plusminusbutton"
//                             onClick={() =>
//                               updateItemQuantity(order.id, itemIndex, -1)
//                             }
//                             disabled={loading}
//                           >
//                             <FaMinus />
//                           </button>
//                           <span>{item.quantity}</span>
//                           <button
//                             className="c-o-plusminusbutton"
//                             onClick={() =>
//                               updateItemQuantity(order.id, itemIndex, 1)
//                             }
//                             disabled={loading}
//                           >
//                             <FaPlus />
//                           </button>
//                         </div>
//                       ) : (
//                         <div className="btnqtyelse">
//                           <span>{item.quantity}</span>
//                         </div>
//                       )}
//                     </div>
//                     {item.notAvailable && (
//                       <p style={{ color: "red" }}>Not Available</p>
//                     )}
//                   </div>
//                 ))}
//                 {order.currentStatusDisplayName === "Completed" && (
//                   <div className="order-total">
//                     <p>Total Amount: Rs{order.totalAmount.toFixed(2)}</p>
//                   </div>
//                 )}
//                 <div className="accept-reject-button">
//                   {renderButtons(order)}
//                 </div>
//               </div>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// };

// export default CurrentOrders;

import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./currentOrder.css";
import { FaMinus, FaPlus } from "react-icons/fa";
import axios from "axios";
import NavBar from "./Navbar";
import { useParams } from "react-router-dom";

const CurrentOrders = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  const { Bid } = useParams();

  // Memoize the recalculation function to prevent unnecessary recreations
  const recalculateOrderTotals = useCallback((order) => {
    const totalAmount = order.orderDetailItems.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    const sgst = totalAmount * 0.09;
    const cgst = totalAmount * 0.09;
    const total = totalAmount + sgst + cgst;

    return {
      ...order,
      totalAmount,
      sgst,
      cgst,
      total,
    };
  }, []);

  // Memoize the update item quantity logic
  const updateItemQuantity = useCallback(
    (orderId, itemIndex, delta) => {
      setDatas((prevDatas) => {
        return prevDatas.map((order) => {
          if (order.id === orderId) {
            const updatedItems = [...order.orderDetailItems];
            if (!updatedItems[itemIndex].notAvailable) {
              const newQuantity = updatedItems[itemIndex].quantity + delta;
              if (newQuantity > 0) {
                updatedItems[itemIndex] = {
                  ...updatedItems[itemIndex],
                  quantity: newQuantity,
                };
              } else {
                updatedItems.splice(itemIndex, 1);
              }
            }
            const updatedOrder = {
              ...order,
              orderDetailItems: updatedItems,
            };
            return recalculateOrderTotals(updatedOrder);
          }
          return order;
        });
      });
    },
    [recalculateOrderTotals]
  );

  // Memoize status update handler
  const handleStatusUpdate = useCallback(
    async (id, statusEndpoint) => {
      if (loading) return;

      try {
        setLoading(true);
        const confirmed = window.confirm(
          `Are you sure you want to ${statusEndpoint} this order?`
        );
        if (!confirmed) return;

      

        await axios.put(
          `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/${id}/complete`
        );

        await fetchCurrentOrders();
      } catch (error) {
        console.error("API call error:", error);
        alert("Failed to update order status. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [loading]
  );
  const handleStatusReject = useCallback(
    async (id, statusEndpoint) => {
      if (loading) return;

      try {
        setLoading(true);
        const confirmed = window.confirm(
          `Are you sure you want to ${statusEndpoint} this order?`
        );
        if (!confirmed) return;

      

        await axios.put(
          `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/${id}/reject`
        );

        await fetchCurrentOrders();
      } catch (error) {
        console.error("API call error:", error);
        alert("Failed to update order status. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [loading]
  );

  // Separate fetch method
  const fetchCurrentOrders = useCallback(async () => {
    try {
      const businessId = localStorage.getItem("businessId");
      const responseGet = await axios.get(
        `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/businesses/${Bid}/orders/order_pending`
      );
      setDatas(responseGet.data);
    } catch (error) {
      console.error(error);
      alert("Failed to fetch current orders");
    }
  }, []);

  // Use useEffect for initial data fetch
  useEffect(() => {
    fetchCurrentOrders();
  }, [fetchCurrentOrders]);

  // Memoize the render buttons function
  const renderButtons = useCallback(
    (order) => {
      const status = order.currentStatusDisplayName?.toLowerCase() || "";

      switch (status) {
        case "created":
          return (
            <>
              <button
                type="button"
                style={{ padding: "10px 30px", marginRight: "10px" }}
                className="btn btn-success"
                onClick={() => handleStatusUpdate(order, "completed")}
                disabled={loading}
              >
                {loading ? "Processing..." : "Accept"}
              </button>
              <button
                type="button"
                style={{ padding: "10px 30px" }}
                className="btn btn-danger"
                onClick={() => handleStatusUpdate(order, "reject")}
                disabled={loading}
              >
                {loading ? "Processing..." : "Reject"}
              </button>
            </>
          );
        case "accepted":
          return (
            <button
              type="button"
              style={{ padding: "10px 30px" }}
              className="btn btn-success"
              onClick={() => handleStatusUpdate(order.id, "completed")}
              disabled={loading}
            >
              {loading ? "Processing..." : "Complete"}
            </button>
          );
        case "completed":
          return (
            <button
              type="button"
              style={{ padding: "10px 30px" }}
              className="btn btn-success"
              onClick={() => handleStatusUpdate(order, "paid")}
              disabled={loading}
            >
              {loading ? "Processing..." : "Paid"}
            </button>
          );
        default:
          return null;
      }
    },
    [handleStatusUpdate, loading]
  );

  // Memoize the order rendering
  const renderOrderItems = useMemo(() => {
    return datas.map((order) => (
      <div className="current-orders-category" key={order.id}>
        <h6 style={{color:"red"}}>
          Order#: {order.id} 
        </h6>
        {order.orderItems.map((item, itemIndex) => (
          <div className="c-o-cartitems" key={itemIndex}>
            <div className="price_per_qty">
              <p>{item.itemName}</p>
              {order.currentStatusDisplayName === "Created" ? (
                <div className="btnqty">
                  <button
                    className="c-o-plusminusbutton"
                    onClick={() => updateItemQuantity(order.id, itemIndex, -1)}
                    disabled={loading}
                  >
                    <FaMinus />
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    className="c-o-plusminusbutton"
                    onClick={() => updateItemQuantity(order.id, itemIndex, 1)}
                    disabled={loading}
                  >
                    <FaPlus />
                  </button>
                </div>
              ) : (
                <div className="btnqtyelse">
                  <span>{item.quantity}</span>
                </div>
              )}
            </div>
            {item.notAvailable && <p style={{ color: "red" }}>Not Available</p>}
          </div>
        ))}
        {order.currentStatusDisplayName === "Completed" && (
          <div className="order-total">
            <p>Total Amount: Rs{order.totalAmount.toFixed(2)}</p>
          </div>
        )}
        <div className="accept-reject-button">
          <button
            type="button"
            style={{ padding: "10px 30px" }}
            className="btn btn-success"
            onClick={() => handleStatusUpdate(order.id, "completed")}
            disabled={loading}
          >
            {loading ? "Processing..." : "Complete"}
          </button>
          <button
                type="button"
                style={{ padding: "10px 30px" }}
                className="btn btn-danger"
                onClick={() => handleStatusReject(order.id, "reject")}
                disabled={loading}
              >
                {loading ? "Processing..." : "Reject"}
              </button>
        </div>
      </div>
    ));
  }, [datas, renderButtons, updateItemQuantity, loading]);

  return (
    <div>
      {/* <NavBar /> */}
      <div className="main_2_div">
        {datas.length === 0 ? (
          <p>No orders available.</p>
        ) : (
          <ul className="cart">{renderOrderItems}</ul>
        )}
      </div>
    </div>
  );
};

export default React.memo(CurrentOrders);
