import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Form, ButtonGroup } from "react-bootstrap";
import { LuIndianRupee } from "react-icons/lu";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import { IoCart } from "react-icons/io5";
import GridLoader from "react-spinners/GridLoader";
import Card from "react-bootstrap/Card";
import confetti from "canvas-confetti";
import { WebSocketContext } from "../context/WebSocketContext";
import logo from "../datas/foodlogo.jpg";
import Choco_Brownie_Shake from "../datas/brownie.jpeg";
import Kit_Kat_Crunchy_Shake from "../datas/kitkat.jpeg";
import Ultimate_Flavour_Bomb from "../datas/ultimatecopy.jpeg";
import Oreo_Chocolate_Shake from "../datas/oreo.jpeg";

const AdvisorMenu = () => {
  const { stompClient, connected, connectWebSocket } =
    useContext(WebSocketContext);
  const [menu, setMenu] = useState([]);
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [showModalOrder, setShowModalOrder] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [online, setOnline] = useState(false);
  const [orderNo, setOrderNo] = useState();
  const navigate = useNavigate();
  const { id } = useParams();

  const imageMap = {
    "Choco Brownie Shake": Choco_Brownie_Shake,
    "Kit Kat Crunchy Shake": Kit_Kat_Crunchy_Shake,
    "Ultimate Flavour Bomb": Ultimate_Flavour_Bomb,
    "Oreo Chocolate Shake": Oreo_Chocolate_Shake,
  };

  const handleCheckout = () => {
    if (!connected || !stompClient.current) {
      console.error("STOMP client is not connected yet");
      alert("WebSocket connection is not established. Please try again later.");
      return;
    }

    // Calculate the total price
    const totalPrice = items.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );

    console.log("Total price for checkout:", totalPrice);

    try {
      stompClient.current.send(
        "/topic/orderStatus",
        {},
        JSON.stringify({
          action: "checkout",
          items,
          totalPrice,
          orderNo,
        })
      );

      console.log("Checkout details sent to WebSocket:", {
        action: "checkout",
        items,
        totalPrice,
      });

      // Clear cart after sending the message
      // setItems([]);

      // Navigate to success page
    } catch (error) {
      console.error("Error during WebSocket checkout:", error);
      alert("Checkout failed. Please try again.");
    }
  };
  const handlePhoneSubmit = () => {
    if (phoneNumber.length < 10) {
      alert("Please enter a valid mobile number.");
    } else {
      setShowModal(false);
      console.log("Phone number submitted. Connecting WebSocket...");
      connectWebSocket(); // This will initiate the connection and the reconnect logic if disconnected
    }
  };

  useEffect(() => {
    // Fetch menu
    const getMenu = async () => {
      try {
        const response = await axios.get(
          `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/business/${id}`
        );

        setMenu(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching menu:", error);
        setLoading(false);
      }
    };

    // Fetch order number
    const fetchOrderNo = async () => {
      try {
        const response = await axios.get(
          `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/orders/next-id`
        );
        console.log("order no - ", response);
        setOrderNo(response.data); // Set the fetched orderNo
      } catch (error) {
        console.error("Error fetching order number:", error);
      }
    };

    // Subscribe to WebSocket topic only when connected
    let subscription;
    if (connected && stompClient.current) {
      subscription = stompClient.current.subscribe(
        "/topic/orderUpdates", // Topic for order updates
        (message) => {
          try {
            const data = JSON.parse(message.body);
            if (data.orderNo) {
              setOrderNo(data.orderNo); // Update orderNo dynamically via WebSocket
            }
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        }
      );
    }

    // Fetch menu and orderNo on component mount
    getMenu();
    fetchOrderNo();

    // Cleanup subscription on component unmount
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [connected, stompClient]);

  // Function to refetch order number
  const refetchOrderNo = async () => {
    try {
      const response = await axios.get(
        `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/orders/next-id`
      );
      setOrderNo(response.data); // Update the orderNo after success
    } catch (error) {
      console.error("Error refetching order number:", error);
    }
  };

  const handleAddItemClick = (item) => {
    if (!connected || !stompClient.current) {
      console.error("STOMP client is not connected yet");
      return;
    }

    const updatedItems = [...items];
    const existingItemIndex = updatedItems.findIndex((i) => i.id === item.id);

    if (existingItemIndex > -1) {
      updatedItems[existingItemIndex].quantity += 1;
    } else {
      updatedItems.push({ ...item, quantity: 1 });
    }

    const totalPrice = updatedItems.reduce(
      (sum, currentItem) => sum + currentItem.price * currentItem.quantity,
      0
    );

    setItems(updatedItems);

    stompClient.current.send(
      "/topic/orderStatus",
      {},
      JSON.stringify({
        items: updatedItems,
        action: "addItem",
        totalPrice,
        orderNo,
      })
    );
  };

  const handleMinusItemClick = (item) => {
    if (!connected || !stompClient.current) {
      console.error("STOMP client is not connected yet");
      return;
    }

    const updatedItems = [...items];
    const existingItemIndex = updatedItems.findIndex((i) => i.id === item.id);

    if (existingItemIndex > -1) {
      updatedItems[existingItemIndex].quantity -= 1;

      if (updatedItems[existingItemIndex].quantity === 0) {
        updatedItems.splice(existingItemIndex, 1);
      }
    }

    const totalPrice = updatedItems.reduce(
      (sum, currentItem) => sum + currentItem.price * currentItem.quantity,
      0
    );

    setItems(updatedItems);

    stompClient.current.send(
      "/topic/orderStatus",
      {},
      JSON.stringify({
        items: updatedItems,
        action: "minusItem",
        totalPrice,
        orderNo,
      })
    );
  };

  const handleConfirmOrder = async () => {
    const orderDetailItems = items.map((item) => ({
      itemId: item.id,
      itemName: item.name,
      quantity: item.quantity,
      price: item.price,
      total: item.price * item.quantity,
    }));
    const totalSales = orderDetailItems.reduce(
      (sum, item) => sum + item.total,
      0
    );

    const data = {
      order: {
        totalSales,
        total_cash: online ? 0 : totalSales,
        total_online: online ? totalSales : 0,
        total_expense_cash: 0,
        total_expense_online: 0,
        udhariGiven: 0,
        udhariReceived: 0,
        orderItems: orderDetailItems,
        orderDate: new Date().toISOString(),
      },
    };

    try {
      await axios.post(
        `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/${id}/save-analyzed-data`,

        data
      );

      setItems([]);
      stompClient.current.send(
        "/topic/orderStatus",
        {},
        JSON.stringify({ items: [], action: "confirm", totalPrice: 0, orderNo })
      );

      // Refetch the order number after successful confirmation
      refetchOrderNo();
    } catch (error) {
      console.error("Error during order confirmation:", error);
      alert("Order confirmation failed. Please try again.");
    }
  };

  const handleCardClick = (online) => {
    setOnline(online); // Update the state
    setShowModalOrder(false); // Close the modal
    handleConfirmOrder(); // Call the API
  };

  if (loading) {
    return (
      <div className="loader-overlay">
        <GridLoader size={50} color="black" />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="loader-overlay">
        <GridLoader size={50} color="black" />
      </div>
    );
  }

  return (
    <div style={{}}>
      <Modal show={showModal} onHide={() => setShowModal(true)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Mobile Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your mobile number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePhoneSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {menu.length === 0 ? (
        <Card style={{ width: "20rem", margin: "20px" }}>
          <Card.Body>
            <Card.Title>No Menu To Show</Card.Title>
            <Card.Text>
              Please add a menu for this business to display items.
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <div className="menu_main">
          {menu.map((item) => (
            <div key={item.id} className="menu_item">
              <div className="menu_desc">
                <h6>{item.name}</h6>
                <div className="btnprice">
                  <h6>
                    <LuIndianRupee /> {item.price || 0}{" "}
                    {/* Default to 0 if price is null */}
                  </h6>
                  {items.some((cartItem) => cartItem.id === item.id) ? (
                    <div className="btnqtymenu">
                      <button
                        type="button"
                        className="plusminusbuttonmenu"
                        onClick={() => handleAddItemClick(item)}
                        disabled={!connected}
                      >
                        <FaPlus />
                      </button>
                      <span>
                        {items.find((cartItem) => cartItem.id === item.id)
                          ?.quantity || "Add"}
                      </span>
                      <button
                        type="button"
                        className="plusminusbuttonmenu"
                        onClick={() => handleMinusItemClick(item)}
                        disabled={!connected}
                      >
                        <FaMinus />
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={() => handleAddItemClick(item)}
                      disabled={!connected}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
              <img
                className="menu_image"
                src={item.itemImageLink } // Use default image if no match
                alt={item.name}
              />
            </div>
          ))}
        </div>
      )}

      <div className="menu_footer">
        <Button
          variant="primary"
          style={{ margin: "20px" }}
          onClick={handleCheckout}
        >
          Checkout <IoCart /> {items.length}
        </Button>
        <Button
          variant="outline-warning"
          onClick={() => setShowModalOrder(true)}
          style={{ margin: "20px" }}
        >
          Confirm Payment
        </Button>

        {/* Modal with two cards */}
        <Modal
          show={showModalOrder}
          onHide={() => setShowModalOrder(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Payment Method</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            {/* Online Card */}
            <Card
              bg="info"
              text="light"
              style={{
                width: "45%",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(true)}
            >
              <Card.Body>
                <Card.Title>Online</Card.Title>
                <Card.Text>Click Here If Online Payment</Card.Text>
              </Card.Body>
            </Card>

            {/* Offline Card */}
            <Card
              bg="success"
              text="light"
              style={{
                width: "45%",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(false)}
            >
              <Card.Body>
                <Card.Title>Cash</Card.Title>
                <Card.Text>Click Here If Cash Payment</Card.Text>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowModalOrder(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdvisorMenu;
