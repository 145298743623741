import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player/youtube'


const VideoCarouselSlider = (props) => {
  const [videoURL ,setVideoURL] = useState('');
const ID = props.id;
  useEffect(()=>{
    const videoData = async()=>{
      const response = await axios.get(`https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/${ID}/video`)
      console.log("video url _",response)
      setVideoURL(response.data)
    }
     videoData();
  })
 
 

  return (
    <div className="video-carousel-container">
      {videoURL ? (
        <div className="video-slide">
          <video
          style={{width:"430px", height:"auto"}}
            src={videoURL}
            autoPlay
            loop
            muted
            playsInline
            controls
            onError={(e) => {
              console.error(`Error playing video :`, e);
            }}
          />
         {/* <ReactPlayer 
  url={firstVideo.videoLink} 
  playing={true} 
  loop={true} 
  muted={true} 
  width="440px" 
  height="auto" 
  style={{ maxWidth: "100%" }} 
/> */}

        </div>
      ) : (
        <div className="no-videos">No videos available</div>
      )}
    </div>
  );
};

export default VideoCarouselSlider;


